import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
    safeSrc: SafeResourceUrl;
  constructor(private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: {name: number}) {
      console.log(data.name);
      switch (data.name) {
          case 0:
              this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('assets/videos/instructivos/I0.mp4');
              break;
          case 1:
              this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('assets/videos/instructivos/I1.mp4');
              break;
      }
  }

  ngOnInit(): void {
  }



}
